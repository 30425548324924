// Font family

//$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-base:       $font-family-sans-serif;

/* $headings-font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
$headings-font-weight: 600;
/* 
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem; */

$enable-responsive-font-sizes: true;

// Color variations

$primary: #b31c18;
$secondary: #1b3257;

$white: #fff;
$offwhite: #f7f7f7;
$gray-100: $offwhite;
$black: #222;

$body-bg: $white;
$body-color: $black; // text color

$h-line-color: lightgrey;

// for navbar dropdown menu
$dropdown-link-active-bg: darken($offwhite, 20%);

// Navbar
$navbar-bg-color: darken($offwhite, 10%);
$navbar-light-color: lighten($black, 30%);
$navbar-light-hover-color: $black;
$navbar-light-active-color: $secondary;

// Image overlay
$img-overlay-color: $secondary;
$img-overlay-opacity: 0.65;

// Sections
$section-primary-color: $offwhite;
$section-secondary-color: $offwhite;

// Footer
$footer-bg-color: $secondary;
$footer-font-color: $offwhite;
